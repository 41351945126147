import * as esri from "esri-leaflet";
import * as L from "leaflet";

export default class LeafletMapEsri {

    static GetEsriMap(layerName: string): L.TileLayer {
        let esriLayer: L.TileLayer = L.tileLayer('', { attribution: '', id: 'None' });
        switch(layerName) {
            case "Gray":
                esriLayer = esri.basemapLayer("Gray");
                break;
            case "Streets":
                esriLayer = esri.basemapLayer("Streets");
                break;
            case "DarkGray":
                esriLayer = esri.basemapLayer("DarkGray");
                break;
            case "Topographic":
                esriLayer = esri.basemapLayer("Topographic");
                break;
            case "NationalGeographic":
                esriLayer = esri.basemapLayer("NationalGeographic");
                break;
            case "Oceans":
                esriLayer = esri.basemapLayer("Oceans");
                break;
            case "Terrain":
                esriLayer = esri.basemapLayer("Terrain");
                break;
            case "ShadedRelief":
                esriLayer = esri.basemapLayer("ShadedRelief");
                break;
            case "Imagery":
                esriLayer = esri.basemapLayer("Imagery");
                break;
            case "ImageryClarity":
                esriLayer = esri.basemapLayer("ImageryClarity");
                break;
            case "ImageryTransportation":
                esriLayer = esri.basemapLayer("ImageryTransportation");
                break;
            default: 
                break;
        }   
        return esriLayer;
    }
}
