import { LeafletMap } from './LeafletMap';
import { LeafletSelectAreaFeature } from './LeafletSelectAreaFeature';

export function Load() {
    (window as any).LeafletMapEngine = {

        createMapByRef: function (ref: any, context: any) {
            let map = new LeafletMap().createMapByRef(ref, context);
            (window as any).ItemsManager.add(ref, map);
        },

        setMapClickHandlerByRef: function (ref: any, context: any) {
            let map = (window as any).ItemsManager.get(ref);
            map?.setClickHandler(context);
        },

        removeMapClickHandlerByRef: function (ref: any, context: any) {
            let map = (window as any).ItemsManager.get(ref);
            map?.removeClickHandler(context);
        },

        captureImageByRef: function (ref: any) {
            let map = (window as any).ItemsManager.get(ref);
            return map?.captureImage();
        },

        //addLayersByRef: function (ref: any, context: any) {
        //    let map = (window as any).ItemsManager.get(ref);
        //    map?.addLayers(context);
        //},

        addPolygonLayerByRef: function (ref: any, layerId: string, features: any[], mapLayer: any) {
            let map = (window as any).ItemsManager.get(ref);
            map?.addPolygonLayer(layerId, features, mapLayer);
        },

        addMarkerLayerByRef: function (ref: any, layerId: string, features: any[], mapLayer: any) {
            let map = (window as any).ItemsManager.get(ref);
            map?.addMarkerLayer(layerId, features, mapLayer);
        },

        addWebMapLayerByRef: function (ref: any, layerId: string, mapLayer: any) {
            let map = (window as any).ItemsManager.get(ref);
            map?.addWebMapLayer(layerId, mapLayer);
        },

        appendToPolygonLayerByRef: function (ref: any, layer: any, features: any[], defaultFeatures: any[]) {
            let map = (window as any).ItemsManager.get(ref);
            map?.appendToPolygonLayer(layer, features, defaultFeatures);
        },

        appendToMarkerLayerByRef: function (ref: any, layer: any, features: any[], defaultFeatures: any[]) {
            let map = (window as any).ItemsManager.get(ref);
            map?.appendToMarkerLayer(layer, features, defaultFeatures);
        },

        updateFeatureTooltipInMarkerLayerByRef: function (ref: any, id: any, layerId: any, tooltip: any) {
            let map = (window as any).ItemsManager.get(ref);
            map?.updateFeatureTooltipInMarkerLayer(id, layerId, tooltip);
        },

        updateFeatureTooltipInPolygonLayerByRef: function (ref: any, id: any, layerId: any, tooltip: any) {
            let map = (window as any).ItemsManager.get(ref);
            map?.updateFeatureTooltipInPolygonLayer(id, layerId, tooltip);
        },

        closeMarkerTooltipByRef: function (ref: any, id: any, layerId: any) {
            let map = (window as any).ItemsManager.get(ref);
            map?.closeMarkerTooltip(id, layerId);
        },

        closePolygonTooltipByRef: function (ref: any, id: any, layerId: any) {
            let map = (window as any).ItemsManager.get(ref);
            map?.closePolygonTooltip(id, layerId);
        },

        removeFromLayerByRef: function (ref: any, layerId: string, featureIds: string[]) {
            let map = (window as any).ItemsManager.get(ref);
            map?.removeFromLayer(layerId, featureIds);
        },

        removeLayerByRef: function (ref: any, layerId: string) {
            let map = (window as any).ItemsManager.get(ref);
            map?.removeLayer(layerId);
        },

        toggleLayerByRef: function (ref: any, layerId: string, visible: boolean) {
            let map = (window as any).ItemsManager.get(ref);
            map?.toggleLayer(layerId, visible);
        },

        getZoomLevelByRef: function (ref: any) {
            let map = (window as any).ItemsManager.get(ref);
            return map?.getZoomLevel() ?? 1000;
        },

        getCenterByRef: function (ref: any) {
            let map = (window as any).ItemsManager.get(ref) as LeafletMap;
            return map?.getCenter();
        },

        //getMapImageByRef: function (ref: any, width: number, height:number) {
        //    let map = (window as any).ItemsManager.get(ref) as LeafletMap;
        //    return map?.getMapImage(width, height);            
        //},    

        setMouseWheelZoomByRef: function (ref: any, enable: boolean) {
            let map = (window as any).ItemsManager.get(ref);
            return map?.setMouseWheelZoom(enable);
        },

        setColorOnPolygonsByRef: function (ref: any, layerId: string, color: string, selectedIds: string[]) {
            let map = (window as any).ItemsManager.get(ref);
            map?.setColorOnPolygons(layerId, color, selectedIds);
        },

        setColorsOnPolygonsByRef: function (ref: any, layerId: string, colorIds: any[]) {
            let map = (window as any).ItemsManager.get(ref);
            map?.setColorsOnPolygons(layerId, colorIds);
        },

        setColorOnMarkersByRef: function (ref: any, layerId: string, color: string, selectedIds: string[]) {
            let map = (window as any).ItemsManager.get(ref);
            map?.setColorOnMarkers(layerId, color, selectedIds);
        },

        setColorsOnMarkersByRef: function (ref: any, layerId: string, colorIds: any[]) {
            let map = (window as any).ItemsManager.get(ref);
            map?.setColorsOnMarkers(layerId, colorIds);
        },

        setBaseTilesByRef: function (ref: any, context: any) {
            let map = (window as any).ItemsManager.get(ref);
            map?.setBaseTiles(context);
        },







        appendPolygonsByRef: function (ref: any, context: any) {
            let map = (window as any).ItemsManager.get(ref);
            map?.appendPolygons(context);
        },

        //setColorOnPolygonsByRef: function (ref: any, options: any, selectedIds: any) {
        //    if (ref === null) return;
        //    let map = (window as any).ItemsManager.get(ref);
        //    if (map === null) return;
        //    map?.setColorOnPolygons(options, selectedIds);
        //},

        setColorAndTooltipOnPolygonsByRef: function (ref: any, context: any) {
            if (ref === null) return;
            let map = (window as any).ItemsManager.get(ref);
            if (map === null) return;
            map?.setColorAndTooltipOnPolygons(context);
        },

        updateMapSettingsByRef: function (ref: any, context: any) {
            let map = (window as any).ItemsManager.get(ref);
            map?.updateMapSettings(context);
        },

        clearLayersByRef: function (ref: any, context: any) {
            let map = (window as any).ItemsManager.get(ref);
            map?.clearLayers(context);
        },

        clearTooltipsByRef: function (ref: any) {
            let map = (window as any).ItemsManager.get(ref);
            map?.clearTooltips();
        },

        appendMarkerByRef: function (ref: any, lat: number, lng: number, popup?: string) {
            let map = (window as any).ItemsManager.get(ref);
            map?.appendMarker(lat, lng, popup);
        },

        appendPointsByRef: function (ref: any, context: any) {
            let map = (window as any).ItemsManager.get(ref);
            map?.appendPoints(context);
        },

        appendControlByRef: function (ref: any, context: any) {
            let map = (window as any).ItemsManager.get(ref);
            map?.appendControl(context);
        },

        fitBoundsByRef: function (ref: any, context: any): any {
            let map = (window as any).ItemsManager.get(ref);
            return map?.fitBounds(context);
        },

        getBoundsByRef: function (ref: any) {
            let map = (window as any).ItemsManager.get(ref);
            return map?.getBounds();
        },

        setZoomByRef: function (ref: any, zoomlevel: number) {
            let map = (window as any).ItemsManager.get(ref);
            return map?.setZoom(zoomlevel);
        },

        panToByRef: function (ref: any, context: any) {
            let map = (window as any).ItemsManager.get(ref);
            return map?.panTo(context);
        },

        setScaleByRef: function (ref: any, scale: number) {
            let map = (window as any).ItemsManager.get(ref);
            return map?.setScale(scale);
        },
    };

    (window as any).LeafletSelectAreaFeature = new LeafletSelectAreaFeature();
}
