﻿import 'leaflet-selectareafeature';

interface SelectedItem {
    id: string,
    color: string;
}

export class LeafletSelectAreaFeature {
    selectfeature: any

    enableAreaSelectorByRef(map: any, context: any) {
    //    map.selectAreaFeature.enable();
    //    map.dragging.disable();

        let geoMap = (window as any).ItemsManager.get(map);
        let me = this;
        this.selectfeature = geoMap.map.selectAreaFeature.enable();
        geoMap.map.dragging.disable();
        this.selectfeature.options.color = '#663399';
        this.selectfeature.options.weight = 3;

        this.selectfeature.onDrawEnd = function () {
            console.log('Draw end');

            if (context.selectorCallback) {
                var areas = me.getSelectedAreasByRef(map, context.kind);
                var args = { selectedItems: areas, kind: context.kind, layerId: context.baseLayerId }
                context.selectorCallback.callee.invokeMethodAsync(context.selectorCallback.function, args);
            }
            me.removeAllAreasDrawnByRef(map);
        }
    }

    disableAreaSelectorByRef(map: any) {
        let geoMap = (window as any).ItemsManager.get(map);
        this.selectfeature.disable();
        this.selectfeature.onDrawEnd = null;
        geoMap.map.dragging.enable();
    }

    removeAllAreasDrawnByRef(ref: any) {
        let map = (window as any).ItemsManager.get(ref);
        map.map.selectAreaFeature.removeAllArea();
    }

    getSelectedAreasByRef(ref: any, kind: string): Array<SelectedItem> {
        let map = (window as any).ItemsManager.get(ref);
        let ret = null;
        try {
            ret = map.map.selectAreaFeature.getFeaturesSelected(kind);
        }
        catch (ex) {
            
        }
        let arraySize = (ret === null ? 0 : ret.length);
        let items = Array<SelectedItem>(arraySize);

        for (let i = 0; i < arraySize; i++) {

            if (kind === 'polygon') {
                let p: SelectedItem = {
                    id: ret[i].options.id,
                    color: ret[i].options.style.fillColor
                }
                items[i] = p;
            }

            if (kind === 'marker') {
                let m: SelectedItem = {
                    id: ret[i].options.id,
                    color: ret[i].options.color
                }
                items[i] = m;
            }
        }
        return items;
    }
}
