import * as L from 'leaflet';
import * as GeoJSON from 'geojson';
//import { Proj } from 'proj4';
import 'proj4leaflet'
import LeafletMapEsri from './LeafletMapEsri';
import { LeafletControl } from './LeafletControl';
import LeafletUtils from './LeafletUtils';
import LeafletMapMapbox from './LeafletMapMapbox';
import { GestureHandling } from "leaflet-gesture-handling";
import { Geometry } from 'esri-leaflet';
import * as LeafletMapToImage from './leafletMapToImage';

L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

interface MapMarkerOptions extends L.MarkerOptions {
    id: string,
    isTooltipOpen: boolean,
    color: string,
    iconName: string,
    text: string,
    image: string,
    imageSize: L.PointTuple | undefined
}
interface MapPolygonOptions extends L.GeoJSONOptions {
    id: string,
    isTooltipOpen: boolean
}
interface MapLayer {
    id: string,
    group: L.LayerGroup,
    visible: boolean,
    settings: any,
    wmslayer: any
}
interface FitBoundsContext {
    fitToSelected: boolean,
    selectedPolygons: string[]
}

export class LeafletMap {
    defaultColor: '#444' | undefined;
    defaultFillColor: '#FFF' | undefined;
    map!: L.Map;
    geoJson: L.GeoJSON<L.Layer>[] = [];
    tileLayer: L.TileLayer = L.tileLayer('', { attribution: '', id: 'None' });
    mapLayers: MapLayer[] = [];
    featureCollection!: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;
    currentFeatureGroup!: L.FeatureGroup;
    mapControl!: L.Control;
    clearStyle: L.PathOptions = { color: this.defaultColor, fillColor: this.defaultFillColor, fillOpacity: 0, weight: 0 };
    selectedPolygons!: L.GeoJSON<any>[];
    scale: number = 1;

    createMapByRef(ref: any, context: any) {
        let lat = context.lat || 59.862091 + 3.0;
        let lng = context.lng || 15.820536;
        let zoom = context.zoom || 6;
        this.scale = context.scale || 1;

        let options = {
            scrollWheelZoom: context.shouldScrollWheelZoom,
            gestureHandling: false
        };

        this.map = L.map(ref, options as any).setView([lat, lng], zoom);
        this.mapLayers = [];

        this.featureCollection = <GeoJSON.FeatureCollection>{};
        this.clearLayers(context);

        this.setMoveHandler(context);
        this.setZoomHandler(context);

        this.setBaseTiles(context);
        //this.addLayers(context);

        return this;
    }

    setMoveHandler(context: any) {
        if (context.onMoveHandler) {
            this.map.on('moveend', function (e: L.LeafletEvent) {
                let zoom = (e.target as L.Map).getZoom();
                let center = (e.target as L.Map).getCenter();
                let bounds = (e.target as L.Map).getBounds();
                let args = { zoom: zoom, latitude: center.lat, longitude: center.lng, bounds: bounds };

                return context.onMoveHandler.callee.invokeMethodAsync(context.onMoveHandler.function, args);
            });
        }
    }

    setZoomHandler(context: any) {
        if (context.onZoomHandler) {
            this.map.on("zoomend", function (e: L.LeafletEvent) {
                let zoom = (e.target as L.Map).getZoom();
                let center = (e.target as L.Map).getCenter();
                let bounds = (e.target as L.Map).getBounds();
                let args = { zoom: zoom, latitude: center.lat, longitude: center.lng, bounds: bounds };

                context.onZoomHandler.callee.invokeMethodAsync(context.onZoomHandler.function, args);
            });
        }
    }

    setClickHandler(context: any) {
        if (context.onClickCHandler) {
            this.map.on('click', function (e: L.LeafletEvent) {
                let zoom = (e.target as L.Map).getZoom();
                let center = (e.target as L.Map).getCenter(); // Get lat/lng from event it self?

                return context.onClickHandler.callee.invokeMethodAsync(context.onClickHandler.function, { zoom: zoom, latitude: center.lat, longitude: center.lng });
            });
        }
    }

    removeClickHandler(context: any) {
        this.map.off('click'); // Remove all click handlers
    }

    async captureImage() {
        let capture = new LeafletMapToImage.default();
        return await capture.Generate(this.map, this.scale);
    }

    setBaseTiles(context: any) {
        this.map.removeLayer(this.tileLayer);

        if (context.baseTileProviderName === "ESRI") {
            this.tileLayer = LeafletMapEsri.GetEsriMap(context.baseTileLayerName);
        }
        else if (context.baseTileProviderName === "None") {
            this.tileLayer = L.tileLayer('', { attribution: '', id: 'None' });
        }
        else {
            this.tileLayer = new LeafletMapMapbox(context).GetMapboxMap(context.baseTileLayerName);
        }

        this.tileLayer.setZIndex(0);
        this.map.addLayer(this.tileLayer);
    }

    addPolygonLayer(layerId: string, features: any[], settings: any) {
        let polygonGroup = L.layerGroup();
        let layer: MapLayer = { group: polygonGroup, visible: true, id: layerId, settings, wmslayer: null };

        this.mapLayers.push(layer);
        let className = this.getLayerClass(layer);

        features.forEach(f => {
            if (f && f.id && f.geo) {
                let polygon = this.createPolygon(f, className, layerId);
                polygon.addTo(polygonGroup);
            }
            else
                console.log(f.id + ' has no polygon');
        });

        polygonGroup.addTo(this.map);

        return layer;
    }

    appendToPolygonLayer(_layer: any, features: any[], defaultFeatures: any[]) {
        let layer = this.mapLayers.find(l => l.id == _layer.id);
        if (!layer)
            layer = this.addPolygonLayer(_layer.id, defaultFeatures, _layer.mapLayer);

        let polygonGroup = layer.group;
        let className = this.getLayerClass(layer);

        features.forEach(f => {
            if (f.geo) {
                let polygon = this.createPolygon(f, className, _layer.id);
                polygon.addTo(polygonGroup);
            }
            else {
                console.log('Polygon with id ' + f.id + ' do not have a polygon.');
            }
        });

    }

    addMarkerLayer(layerId: string, features: any[], settings: any) {
        let markerGroup = L.layerGroup();
        let layer: MapLayer = { group: markerGroup, visible: true, id: layerId, settings, wmslayer: null };

        this.mapLayers.push(layer);
        let className = this.getLayerClass(layer);

        features.forEach(f => {
            let marker = this.createMarker(f, className, layerId);
            marker.addTo(markerGroup);
        });

        markerGroup.addTo(this.map);

        return layer;
    }

    appendToMarkerLayer(_layer: any, features: any[], defaultFeatures: any[]) {
        let layer = this.mapLayers.find(l => l.id == _layer.id);
        if (!layer)
            layer = this.addMarkerLayer(_layer.id, defaultFeatures, _layer.mapLayer);

        let markerGroup = layer.group;
        let className = this.getLayerClass(layer);

        features.forEach(f => {
            let marker = this.createMarker(f, className, _layer.id);
            marker.addTo(markerGroup);
        });
    }

    closeAllTooltips(id: any) {
        for (let layer of this.mapLayers) {
            let items: L.LayerGroup | undefined = layer?.group;
            items?.eachLayer(i => {
                let options = i.options as MapMarkerOptions;
                i.unbindTooltip();
                i.closeTooltip();
                options.isTooltipOpen = false;
            });
        }
    }

    closeMarkerTooltip(id: any, layerId: any) {
        let layer: MapLayer | undefined = this.mapLayers.find(l => l.id == layerId);
        let items: L.LayerGroup | undefined = layer?.group;
        items?.eachLayer(i => {
            let options = i.options as MapMarkerOptions;
            if (options.id == id) {
                i.unbindTooltip();
                i.closeTooltip();
                options.isTooltipOpen = false;
            }
        });
    }

    closePolygonTooltip(id: any, layerId: any) {
        let layer: MapLayer | undefined = this.mapLayers.find(l => l.id == layerId);
        let items: L.LayerGroup | undefined = layer?.group;
        items?.eachLayer(i => {
            let options = i.options as MapPolygonOptions;
            if (options.id == id) {
                i.unbindTooltip();
                i.closeTooltip();
                options.isTooltipOpen = false;
            }
        });
    }

    updateFeatureTooltipInMarkerLayer(id: any, layerId: any, tooltip: any) {
        let layer: MapLayer | undefined = this.mapLayers.find(l => l.id == layerId);
        let items: L.LayerGroup | undefined = layer?.group;

        this.closeAllTooltips(id);

        items?.eachLayer(i => {
            let options = i.options as MapMarkerOptions;

            if (options.id == id) {
                i.unbindTooltip();
                i.bindTooltip(tooltip, {
                    permanent: true,
                    interactive: true
                });
                i.openTooltip();
                options.isTooltipOpen = true;
            }
        });
    }

    updateFeatureTooltipInPolygonLayer(id: any, layerId: any, tooltip: any) {
        let layer: MapLayer | undefined = this.mapLayers.find(l => l.id == layerId);
        let items: L.LayerGroup | undefined = layer?.group;

        this.closeAllTooltips(id);

        items?.eachLayer(i => {
            let options = i.options as MapPolygonOptions;

            if (options.id == id) {
                i.unbindTooltip();
                i.bindTooltip(tooltip, {
                    permanent: true,
                    interactive: true
                });
                i.openTooltip();
                options.isTooltipOpen = true;
            }
        });
    }

    addWebMapLayer(layerId: string, mapLayer: any) {
        //let webMap = settings?.webMap;
        //let sublayers = settings.webMapSelectedLayers.map((x: any) => x.name).toString();

        let wmsLayer = L.tileLayer.wms(mapLayer.url, {
            //crs: this.createCRS(),
            layers: mapLayer.layers,
            transparent: true,
            format: "image/png",
            version: "1.3.0",
            tileSize: 2048,
            attribution: mapLayer.attribution
        })

        const existingWebLayer = this.mapLayers.find(layer => layer.id === layerId);
        if (existingWebLayer)
            return;

        let wmsGroup = L.layerGroup();
        this.mapLayers.push({
            id: layerId,
            settings: mapLayer,
            visible: true,
            group: wmsGroup,
            wmslayer: wmsLayer
        });
        wmsLayer.addTo(this.map);
    }

    createCRS(): L.CRS {
        try {
            const crsStr = 'EPSG:25833';
            const crsProj4 = '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs';
            const crsTileOrg: [number, number] = [-2500000, 9045984];
            const crsBaseResolution = 21664;
            const crsMaxZoom = 18;
            const crs = new L.Proj.CRS(crsStr, crsProj4,
                {
                    origin: crsTileOrg,
                    resolutions: Array.from(Array(crsMaxZoom + 2), (e, zoomLevel) => crsBaseResolution / Math.pow(2, zoomLevel))
                    //resolutions: [8192, 4096, 2048]
                });

            return crs;
        }
        catch (ex) {
            console.log(ex);
        }
        return new L.Proj.CRS('', '', {});
    }

    removeLayer(layerId: string) {
        let layer: MapLayer | undefined = this.mapLayers.find(l => l.id == layerId);

        if (!layer)
            return;

        let layerGroup = layer.wmslayer ? layer.wmslayer : layer.group;
        this.map.removeLayer(layerGroup);
        const index = this.mapLayers.indexOf(layer);
        if (index > -1)
            this.mapLayers.splice(index, 1);
    }

    toggleLayer(layerId: string, visible: boolean) {
        let layer: MapLayer | undefined = this.mapLayers.find(l => l.id == layerId);

        if (!layer)
            return;

        let selector = '.l_' + layerId;
        let elements = document.querySelectorAll(selector);
        layer.visible = visible;

        if (visible)
            elements?.forEach(e => e.classList.remove('d-none'));
        else
            elements?.forEach(e => e.classList.add('d-none'));
    }

    getLayerClass(layer: any) {
        let visible = layer.visible;
        return 'l_' + layer.id + (visible ? '' : ' d-none');
    }

    //appendToLayer(layerId: string, features: any[]) {
    //    var layerGroup = this.mapLayers[layerId];
    //    if (layerGroup) {
    //        //features.forEach(f => layerGroup.addLayer(f))
    //        this.appendToLayerGroup(layerGroup, features);
    //    }

    //    //    layerGroup.eachLayer(function (layer: L.LayerGroup) {
    //    //        if (layer.Id._leaflet_id === id) {
    //    //            mylayergroup.removeLayer(layer)
    //    //        }
    //    //    });	
    //}

    createPolygon(item: any, className: string, layerId: string): any {
        let polygon = item.geo;
        let fillColor = item.fillColor ?? this.defaultFillColor;
        let style = <L.PathOptions>{ color: item.color ?? this.defaultColor, fillColor: fillColor, fillOpacity: 1.0, className: className, weight: 1, };
        let geojsonObject = LeafletUtils.GetValidGeoJson(polygon);

        geojsonObject[1].properties = { tooltip: item.tooltip, id: item.id };

        let options: MapPolygonOptions = { id: item.id, style: style, onEachFeature: this.onEachFeature, isTooltipOpen: false };
        let polygonLayer: L.Layer = L.geoJSON<MapPolygonOptions>(geojsonObject[1], options);

        if (item.tooltip)
            polygonLayer.bindTooltip(item.tooltip);

        if (item.onClickHandler) {
            polygonLayer.on('click', e => {
                var point = e.latlng;

                //need to define click sender (polygon or tooltip)
                if (e.layer.feature !== undefined) { //polygon
                    var properties = { id: item.id, type: 1, latitude: point.lat, longitude: point.lng, color: fillColor, layerId, isFromTooltip: false }; // type=1 => Polygon
                    item.onClickHandler.callee.invokeMethodAsync(item.onClickHandler.function, properties);
                }

                let _container = e.layer._container;
                if (_container) { //tooltip
                    let tooltipClasses = _container.className;
                    if (tooltipClasses.includes("leaflet-tooltip")) {
                        var properties = { id: item.id, type: 1, latitude: point.lat, longitude: point.lng, color: fillColor, layerId, isFromTooltip: true }; // type=1 => Polygon
                        item.onClickHandler.callee.invokeMethodAsync(item.onClickHandler.function, properties);
                    }
                }
            });
        }

        if (item.onHoverHandler) {
            polygonLayer.on('mouseover', e => {

                let layer: MapLayer | undefined = this.mapLayers.find(l => l.id == layerId);
                let items: L.LayerGroup | undefined = layer?.group;

                items?.eachLayer(i => {
                    let options = i.options as MapMarkerOptions;
                    if (options.id == item.id) {
                        if (!options.isTooltipOpen) {
                            var properties = { id: item.id, layerId: layerId, type: 1 }; // type=1 => Polygon
                            item.onHoverHandler.callee.invokeMethodAsync(item.onHoverHandler.function, properties)
                        }
                    }
                });
            });
        }

        return polygonLayer;
    }

    createMarker(item: any, markerClass: string, layerId: string) {
        let latitude = item.latitude;
        let longitude = item.longitude;
        let marker = null;

        if (item.text) {
            let point = new L.DivIcon({
                className: 'marker marker-point ' + markerClass,
                html: `<span style="font-weight: bold;">${item.text}</span>`,
            });
            let options: MapMarkerOptions = { id: item.id, icon: point, draggable: false, isTooltipOpen: false, color: item.color, iconName: '', text: item.text, image: '', imageSize: undefined };
            marker = L.marker([latitude, longitude], options);
        }
        else if (item.icon) {
            let colorStyle = item.color ? `color: ${item.color}` : '';
            let divIcon = new L.DivIcon({
                className: 'marker marker-icon ' + markerClass,
                //html: item.icon,
                html: `<i class="material-icons" style="${colorStyle}">${item.icon}</i>`,
                iconSize: [item.width ?? 32, item.height ?? 32]
            });
            let options: MapMarkerOptions = { id: item.id, icon: divIcon, draggable: false, isTooltipOpen: false, color: item.color, iconName: item.icon, text: '', image: '', imageSize: undefined };
            marker = L.marker([latitude, longitude], options);
        }
        else if (item.image) {
            let iconSize: L.PointTuple = [item.width ?? 32, item.height ?? 32];
            let customIcon = L.icon({
                className: 'marker marker-image ' + markerClass,
                iconUrl: item.image,
                iconSize: iconSize,
                iconAnchor: [16, 32]
            });
            let options: MapMarkerOptions = { id: item.id, icon: customIcon, draggable: false, isTooltipOpen: false, color: item.color, iconName: '', text: '', image: item.image, imageSize: iconSize };
            marker = L.marker([latitude, longitude], options);
        }
        else {
            let colorStyle = item.color ? `color: ${item.color}` : '';
            let point = new L.DivIcon({
                className: 'marker marker-point ' + markerClass,
                html: `<i class="material-icons" style="${colorStyle}">location_on</i>`,
                iconSize: [item.width ?? 32, item.height ?? 32]
            });
            let options: MapMarkerOptions = { id: item.id, icon: point, draggable: false, isTooltipOpen: false, color: item.color, iconName: 'location_on', text: '', image: '', imageSize: undefined };
            marker = L.marker([latitude, longitude], options);
        }

        if (item.tooltip)
            marker.bindTooltip(item.tooltip);

        if (item.onClickHandler) {
            marker.on('click', e => {

                var point = e.latlng;

                //need to define click sender (marker or tooltip)
                if (e.layer === undefined) { //marker
                    var properties = { id: item.id, layerId: layerId, type: 2, latitude: point.lat, longitude: point.lng, color: item.color, isFromTooltip: false }; // type=2 => Marker
                    item.onClickHandler.callee.invokeMethodAsync(item.onClickHandler.function, properties);
                }

                let _container = e.layer?._container ?? undefined;
                if (_container !== undefined) { //tooltip
                    let tooltipClasses = _container.className;
                    if (tooltipClasses.includes("leaflet-tooltip")) {
                        var properties = { id: item.id, layerId: layerId, type: 2, latitude: point.lat, longitude: point.lng, color: item.color, isFromTooltip: true }; // type=2 => Marker
                        item.onClickHandler.callee.invokeMethodAsync(item.onClickHandler.function, properties);
                    }
                }

            });
        }

        if (item.onHoverHandler) {
            marker.on('mouseover', e => {

                let layer: MapLayer | undefined = this.mapLayers.find(l => l.id == layerId);
                let items: L.LayerGroup | undefined = layer?.group;

                items?.eachLayer(i => {
                    let options = i.options as MapMarkerOptions;
                    if (options.id == item.id) {
                        if (!options.isTooltipOpen) {
                            var properties = { id: item.id, layerId: layerId, type: 2 }; // type=2 => Marker
                            item.onHoverHandler.callee.invokeMethodAsync(item.onHoverHandler.function, properties)
                        }
                    }
                });
            });
        }

        return marker;
    }

    removeFromLayer(layerId: string, featureIds: string[]) {
        let me = this;
        let layer: MapLayer | undefined = this.mapLayers.find(l => l.id == layerId);
        let items: L.LayerGroup | undefined = layer?.group;

        items?.eachLayer(i => {
            let g = i as L.GeoJSON<MapPolygonOptions>;
            let options = i.options as MapPolygonOptions;

            if (featureIds.indexOf(options.id.toString()) > -1)
                items?.removeLayer(i);
        });
    }

    getZoomLevel() {
        return this.map.getZoom();
    }

    getCenter(): any {
        let zoom = this.map.getZoom();
        let center = this.map.getCenter();
        let bounds = this.map.getBounds();

        return { zoom: zoom, latitude: center.lat, longitude: center.lng, bounds: bounds };
    }

    //getMapImage(width: number, height: number){
    //    const mapElement = document.querySelector(".geomap-container") as HTMLElement;
    //    let result = "";
    //    if (!mapElement) {
    //        console.error('Map element not found');
    //    }

    //    if (mapElement) {
    //        html2canvas(mapElement, { width, height, useCORS: true })
    //            .then(canvas => {
    //                Promise.resolve(result = canvas.toDataURL());
    //            }).catch(error => {
    //                console.error('Error capturing the element:', error);
    //            });
    //        } else {
    //            console.error('Element #capture not found');
    //        }

    //    return result;
    //}


    //addPointLayer(layer: any) {
    //    let markers: L.Marker[] = [];
    //    layer.values?.forEach((v: any) => {
    //        var latitude = v[layer.latitude];
    //        var longitude = v[layer.longitude];
    //        if (latitude && longitude) {
    //            let customIcon = L.icon({
    //                iconUrl: this.getIconUrl(""),
    //                iconSize: [32, 32],
    //                iconAnchor: [16, 32]
    //            });
    //            let marker = L.marker([latitude, longitude], { icon: customIcon });
    //            markers.push(marker);
    //        }
    //    });

    //    let layerGroup = L.layerGroup(markers);
    //    this.mapLayers.push(layerGroup);
    //    this.map.addLayer(layerGroup);
    //}

    onEachFeature(feature: any, layer: any) {
        if (feature.properties && feature.properties.tooltip) {
            layer.bindTooltip(feature.properties.tooltip);
        }
    }

    appendControl(context: any) {
        let control = new LeafletControl();
        let options = {} as L.ControlOptions;
        options.position = 'topright';
        if (this.mapControl) this.mapControl.remove();
        this.mapControl = control.createControl(this.map, options, context);
    }

    appendMarker(lat: number, lng: number, popup?: string) {
        let marker = L.marker([lat, lng]).addTo(this.map);
        if (popup) {
            marker.bindPopup(popup)
                .on('mouseover', function (ev: any) {
                    ev.target.openPopup();
                })
                .on('mouseout', function (ev: any) {
                    ev.target.closePopup();
                });
        }
    }

    appendPoints(context: any) {
        let points: any = [];
        context.points.forEach((p: any) => {
            points.push({
                "id": p.id,
                "type": "Feature",
                "geometry": {
                    "type": "Point",
                    "coordinates": [p.longitude, p.latitude]
                },
                "properties": {
                    "radius": p.radius,
                    "color": p.color,
                    "popupContent": p.popupContent
                }
            });
        });
        let pointsToShow = context.itemsToShow ? points.splice(0, context.itemsToShow) : points;
        L.geoJSON(pointsToShow, {
            pointToLayer: function (feature: any, latlng: any) {
                let markerProperties = { radius: feature.properties.radius, color: feature.properties.color };
                return new (L.circleMarker([latlng.lat, latlng.lng], markerProperties) as any)
                    .bindPopup(feature.properties.popupContent)
                    .on('click', function (_e: any) {
                        return context.onClickHandler.caller.invokeMethodAsync(context.onClickHandler.functionName, { "feature": feature });
                    })
                    .on('mouseover', function (ev: any) {
                        ev.target.openPopup();
                    })
                    .on('mouseout', function (ev: any) {
                        ev.target.closePopup();
                    });
            }
        }).addTo(this.map);
    }

    setColorOnPolygons(layerId: string, color: string, selectedIds: string[]) {
        let me = this;
        let layer: MapLayer | undefined = this.mapLayers.find(l => l.id == layerId);
        let items: L.LayerGroup | undefined = layer?.group;

        items?.eachLayer(i => {
            let g = i as L.GeoJSON<MapPolygonOptions>;
            let options = i.options as MapPolygonOptions;

            if (selectedIds.indexOf(options.id) > -1)
                g.setStyle({ fillColor: color });
        });
    }

    setColorsOnPolygons(layerId: string, colorIds: any) {
        let me = this;
        let layer: MapLayer | undefined = this.mapLayers.find(l => l.id == layerId);
        let items: L.LayerGroup | undefined = layer?.group;

        items?.eachLayer(i => {
            let g = i as L.GeoJSON<any>;
            let options = i.options as MapMarkerOptions;
            //let index = colorIds.indexOf(options.id);
            let color = colorIds[options.id];

            if (color)
                g.setStyle({ fillColor: color });
        });
    }

    setColorOnMarkers(layerId: string, color: string, selectedIds: string[]) {
        let me = this;
        let layer: MapLayer | undefined = this.mapLayers.find(l => l.id == layerId);
        let items: L.LayerGroup | undefined = layer?.group;

        items?.eachLayer(i => {
            let g = i as L.GeoJSON<MapMarkerOptions>;
            let options = i.options as MapMarkerOptions;

            if (selectedIds.indexOf(options.id) > -1)
                me.setColorOnMarker(g, color);
        });
    }

    setColorsOnMarkers(layerId: string, colorIds: any) {
        let me = this;
        let layer: MapLayer | undefined = this.mapLayers.find(l => l.id == layerId);
        let items: L.LayerGroup | undefined = layer?.group;

        items?.eachLayer(i => {
            let g = i as L.GeoJSON<MapMarkerOptions>;
            let options = i.options as MapMarkerOptions;

            let color = colorIds[options.id];
            if (color)
                me.setColorOnMarker(g, color);
        });
    }

    setColorOnMarker(g: L.GeoJSON<MapMarkerOptions>, color: string) {
        // Need to hack to get the element
        let asAny: any = g;
        let element: HTMLElement = asAny._icon?.firstChild;
        element.style.color = color;
    }

    initSelectedPolygons(polygon: L.GeoJSON<any>, selectedIds: any) {
        let me = this;

        selectedIds?.forEach((id: any) => {
            let feature = (polygon as any).feature as GeoJSON.Feature;
            if (me && id === feature.id)
                me.selectedPolygons.push(polygon);
        })
    }

    setColorAndTooltipOnPolygons(context: any) {
        let colorFn = this.colorizePolygon;
        let tooltipFn = this.tooltipPolygon;

        this.geoJson.forEach(p => {
            p.eachLayer(function (player: L.Layer) {
                let g = player as L.GeoJSON<any>;
                colorFn(g, context.options);
                tooltipFn(g, context.tooltips);
            });
        });
    }

    tooltipPolygon(polygon: L.GeoJSON<any>, tooltips: any) {
        tooltips?.forEach((el: any) => {
            let feature = (polygon as any).feature as GeoJSON.Feature;
            if ((el.id == feature.id) && (el.name === feature.properties?.fieldName)) {
                polygon.unbindTooltip();
                polygon.bindTooltip(el?.value, { sticky: true, className: el?.className });
            }
        });
    }

    colorizePolygon(polygon: L.GeoJSON<any>, options: any) {
        options?.forEach((el: any) => {
            let feature = (polygon as any).feature as GeoJSON.Feature;
            if ((el.id == feature.id) && (el.name === feature.properties?.fieldName)) {
                polygon.setStyle(el.style);
            }
        });
    }

    appendPolygons(context: any) {
        this.featureCollection.features?.forEach(f => (f.properties as any).show = true);
        let properties = <GeoJSON.GeoJsonProperties>{};
        let style = <L.PathOptions>{ weight: 1, fillColor: '#FFF', fillOpacity: 0, color: '#444' };

        context.polygonData.forEach((p: any) => {
            p.polygons.forEach((el: any) => {
                properties = { fieldName: p.dimension.name, id: el[p.dimension.name], clicked: false, show: true, style: style };
                let geoJson = LeafletUtils.GetValidGeoJson(el[p.dimension.polygonFieldName]);

                if (geoJson[0]) {
                    this.featureCollection.features.push({
                        id: el[p.dimension.name],
                        type: "Feature",
                        geometry: geoJson[1],
                        properties: properties,
                        bbox: undefined
                    });
                }
                else
                    console.log("Incorrect geojson on polygon field id = " + el[p.dimension.name] + ".");
            });

            let geoJsonLayer = this.renderPolygons(context);
            this.geoJson.push(geoJsonLayer as L.GeoJSON<L.Layer>);
            this.featureCollection.features.forEach(f => {
                (f.properties as any).show = true;
            });
        });
    }

    renderPolygons(context: any): L.Layer {
        let clickHandler = (e: any, feature: GeoJSON.Feature) => {
            let options = [{ id: feature.id, style: { weight: 4 } }];
            let polygon = e.target as L.GeoJSON<any>;
            this.colorizePolygon(polygon, options);

            return context.onClickPolygonCaller
                .invokeMethodAsync(context.onClickPolygonFn, { "properties": feature.properties, "point": e.latlng })
                .then((_r: any) => (feature.properties as any).clicked = true);
        }

        let onEachFeatureHandler = (feature: GeoJSON.Feature, layer: L.Layer) => layer.on({ click: (e) => clickHandler(e, feature) });

        let filterHandler = (feature: GeoJSON.Feature) => (feature.properties as any).show;

        let styleHandler = (feature: any) => feature.properties.style as L.PathOptions;

        let me = this;

        this.map.eachLayer(function (layer: any) {
            me.featureCollection.features.forEach(f => {
                if (layer.feature) {
                    let layerFeatureId = layer.feature.id;

                    if (f.id == layerFeatureId)
                        me.featureCollection.features.splice(me.featureCollection.features.indexOf(f), 1);

                }
            });
        });

        let geoJsonLayer: L.Layer = L.geoJSON(
            me.featureCollection,
            {
                onEachFeature: onEachFeatureHandler,
                style: styleHandler,
                filter: filterHandler
            });

        this.setCurrentFeatureGroup(geoJsonLayer.addTo(this.map))
        return geoJsonLayer;
    }

    setCurrentFeatureGroup(geoJsonLayer: L.Layer) {
        let layers: L.Layer[] = [];
        layers.push(geoJsonLayer);
        this.currentFeatureGroup = new L.FeatureGroup(layers);
    }

    fitBounds(context: FitBoundsContext): any {
        let minLat = Number.MAX_VALUE;
        let maxLat = Number.MIN_VALUE;
        let minLng = Number.MAX_VALUE;
        let maxLng = Number.MIN_VALUE;
        let baseLayer: MapLayer | undefined = this.mapLayers.find(l => l.id == '__BaseLayer__');

        if (baseLayer) {
            let items: L.LayerGroup = baseLayer.group;
            let layers: L.Layer[] = items.getLayers();
            let hasBounds = false;

            layers.forEach((layer: L.Layer) => {
                let polygon = layer as L.GeoJSON<any>;
                let option = polygon.options as MapPolygonOptions;
                let optionId = option.id;
                let idExists = context.selectedPolygons?.find(i => i == optionId);
                let doFitToPolygon = !context.fitToSelected || (context.fitToSelected && idExists);

                if (doFitToPolygon) {
                    if (polygon.getBounds == null) {    // A marker does not have the getBounds method like a polygon has.
                        let marker = layer as L.Marker<any>;
                        let latlng = marker.getLatLng();
                        minLat = Math.min(minLat, latlng.lat);
                        maxLat = Math.max(maxLat, latlng.lat);
                        minLng = Math.min(minLng, latlng.lng);
                        maxLng = Math.max(maxLng, latlng.lng);
                    } else {
                        let bounds = polygon.getBounds();
                        let southWest = bounds.getSouthWest();
                        let northEast = bounds.getNorthEast();

                        minLat = Math.min(minLat, southWest.lat);
                        maxLat = Math.max(maxLat, northEast.lat);
                        minLng = Math.min(minLng, southWest.lng);
                        maxLng = Math.max(maxLng, northEast.lng);
                    }
                    hasBounds = true;
                }
            });
            if (hasBounds) {
                let bounds = L.latLngBounds([minLat, minLng], [maxLat, maxLng]);
                this.map.fitBounds(bounds);
                return bounds;
            }
        }
        let bounds = this.getBounds();
        this.map.fitBounds(bounds);

        return bounds;
    }

    getBounds(): any {
        var bounds = this.map.getBounds();
        return bounds;
    }

    setZoom(zoomlevel: number): any {
        this.map.setZoom(zoomlevel);
    }

    panTo(latlng: L.LatLngExpression): any {
        this.map.panTo(latlng);
    }

    hidePolygons() {
        let style: L.PathOptions = { color: "#444", fillColor: "#FFF", fillOpacity: 0, weight: 1 };
        this.geoJson.forEach(p => {
            p.setStyle(style);
        });
    }

    logPolygons() {
        this.map.eachLayer(function (layer: L.Layer) {
            if (layer instanceof L.GeoJSON) {
                layer.eachLayer(function (flayer: L.Layer) {
                    let feature: GeoJSON.Feature = (flayer as any).feature;
                    console.log(feature);
                    let options: L.PathOptions = (flayer as any).options;
                    console.log(options);
                });
            }
        });
    }

    clearTooltips() {
        let me = this;

        this.map.eachLayer(function (l) {
            if (l.getTooltip) {
                var toolTip = l.getTooltip();
                if (toolTip) {
                    me.map.closeTooltip(toolTip);
                }
            }
        });
    }

    updateMapSettings(context: any) {
        this.clearLayers(context);
        this.setBaseTiles(context);
        //this.addLayers(context);
        this.setMouseWheelZoom(context.shouldScrollWheelZoom);
    }

    clearLayers(context: any) {
        //    if (this.geoJson.length > 0) {
        //        this.geoJson.forEach(p => {
        //            this.map.removeLayer(p);
        //        });
        //    }

        //    for (const property in this.mapLayers) {
        //        let mapLayer = this.mapLayers[property];
        //        this.map.removeLayer(mapLayer);
        //        //console.log(`${property}: ${this.mapLayers[property]}`);
        //    }
        //    //this.mapLayers.forEach((id: string, layerGroup: L.LayerGroup): any => {
        //    //    this.map.removeLayer(layerGroup);
        //    //});

        //    this.geoJson = [];
        //    this.featureCollection.features = [];
        //    this.selectedPolygons = [];
    }

    setMouseWheelZoom(enable: boolean) {
        if (enable)
            this.map.scrollWheelZoom.enable();
        else
            this.map.scrollWheelZoom.disable();
    }

    setScale(scale: number) {
        this.scale = scale;
    }
}
