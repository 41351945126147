import * as L from "leaflet";

const accessToken:string = "pk.eyJ1IjoiemVhbG90LW5vcndheSIsImEiOiJja2c4d3ZleHkwM2oyMnJwdXpidXBsamhpIn0.2Snbmid42Bu1vJ1Myo7U4g";
const attribution:string = 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>';
const maxZoom:number = 20;

export default class LeafletMapMapbox {
    
    accessToken: string;
    attribution: string;
    maxZoom: number;
    
    constructor(context: any) {
        this.accessToken = context.accesToken ?? accessToken;
        this.attribution = context.attribution ?? attribution;
        this.maxZoom = context.maxZoom ?? maxZoom;
    }

    GetMapboxMap(layerName: string): L.TileLayer {
        let mapboxLayer: L.TileLayer = L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            id: "mapbox.streets",
            attribution: this.attribution,
            maxZoom: this.maxZoom,
            accessToken: this.accessToken
        });
        if (layerName === undefined) return mapboxLayer;
        switch(layerName) {
            case "Satellite":
                mapboxLayer = L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token={accessToken}', {
                    id: "mapbox.satellite-streets",
                    attribution: this.attribution,
                    maxZoom: this.maxZoom,
                    accessToken: this.accessToken
                    });
                break;
            case "Streets":
                break;
            default:
                break;
        }
        return mapboxLayer;
    }
}