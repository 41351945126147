import * as L1 from 'leaflet';
import { LeafletSelectAreaFeature } from './LeafletSelectAreaFeature';

namespace L.Control {

    export class CustomControl extends L1.Control {

        initialize = (options: any) => L1.Util.setOptions(this, options);

        onAdd = (_map: L1.Map) => buildContainer((this.options as any).context, _map);

        onRemove = (_map: L1.Map) => {
            // Intentionally left empty
        }
    }

    function buildContainer(context: any, map: L1.Map): HTMLElement {
        var selectAreaFeature = new LeafletSelectAreaFeature();

        let toolBar = L1.DomUtil.create('div', 'leaflet-control-toolbar d-flex');
        let drawElement = L1.DomUtil.create('div', 'leaflet-control-icon');
        let clickElement = L1.DomUtil.create('div', 'leaflet-control-icon');

        // For now Control is made from C#
        //drawElement.addEventListener("click", function () { selectAreaFeature.enableAreaSelectorByRef(map) });
        //clickElement.addEventListener("click", function () { selectAreaFeature.disableAreaSelectorByRef(map) })

        drawElement.innerHTML = "<img src='/img/draw.jpg'/> ";
        clickElement.innerHTML = "<img src='/img/click.jpg'/>";
        toolBar.appendChild(drawElement);
        toolBar.appendChild(clickElement);

        return toolBar;
    }
}

export class LeafletControl {
    createControl(map: L1.Map, options: L1.ControlOptions, context: any) {
        let customControl = function (_options: L1.ControlOptions) {
            let opts = _options as any;
            opts.context = context;
            return new L.Control.CustomControl(opts);
        }
        return customControl(options).addTo(map);
    }
}