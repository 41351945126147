export default class LeafletUtils {

    static GetValidGeoJson(str: any): [boolean, any] {
        let geom: any;
        try {
            geom = JSON.parse(str);
        } catch(e) {
            return [false, geom];
        }
        return [true, geom];
    }
}

